import React, { useEffect, useState } from "react";
import { validateEmail } from "../../Util/ValidationUtil.js";
import Logo from "../../components/Icons/Logo/Logo";
import "./Auth.css";
import styles from "./Auth.module.scss";
import { useDispatch } from "react-redux";
import * as userActions from "../../store/redux/User/actions";
import TermsFooter from "../../components/TermsFooter/TermsFooter";
import Alert from "../../components/Alert/Alert";
import { useHistory } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import LeftImage from "../../assets/SigninPic.jpeg";
import EmailInput from "../../components/Forms/Fields/EmailInput/EmailInput";
import PasswordInput from "../../components/Forms/Fields/PasswordInput/PasswordInput";
import { toast } from "react-toastify";

const Auth = (props) => {
  return (
    <>
      <SignIn />
    </>
  );
};
export default Auth;

export const SignIn = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [smallScreen, setSmallScreen] = useState(false);
  const [screenSize, setScreenSize] = useState(null);
  const [chkRememberMe, setChkRememberMe] = useState(false);
  const [chkAgreeToTerms, setChkAgreeToTerms] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [state, setState] = useState({
    loading: false,
  });

  const signupCallback = (result) => {
    console.log("result");
    if (result === "ok") {
      window.open("/search", "_self");
    }
  };

  const loginCallback = (result) => {
    // //console.log(result);
    if (result === "failed") {
      setState({
        ...state,
        error: {
          msg: "That email and password combination is incorrect.",
          placement: "email",
        },
        loading: false,
      });
    } else {
      history.push("/search");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      loading: false,
      error: {},
    });
    const regexp = /^\S*$/;
    if (email.length === 0) {
      setState({
        ...state,
        error: {
          msg: "Looks like you forgot something",
          placement: "email",
        },
        loading: false,
      });
    } else if (!validateEmail(email)) {
      setState({
        ...state,
        error: {
          msg: "Please enter with a correct email format",
          placement: "email",
        },
        loading: false,
      });
    } else if (password.length === 0) {
      setState({
        ...state,
        error: {
          msg: "Please enter your password",
          placement: "password",
          color: "yellow",
        },
        loading: false,
      });
    } else if (!regexp.test(password) || !regexp.test(password)) {
      setState({
        ...state,
        error: {
          msg: "White or empty space is not allowed",
          placement: "password",
        },
        loading: false,
      });
    } else if (password.length < 6) {
      setState({
        ...state,
        error: {
          msg: "Password must be at least 6 characters long",
          placement: "password",
        },
        loading: false,
      });
    } else {
      const user = {
        email: email,
        password: password.trim(),
      };
      // console.log(user, "user")
      // here is the place to do the 2FA => send email with password of 4 digits and create a page to enter the code 
      // later we will add timing of 2 min for the code to become invalid.
      dispatch(userActions.signIn(user, chkRememberMe, signupCallback));
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 768) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenSize]);
  return (
    <>
      <section className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="row row col-12 col-md-12 d-flex justify-content-center align-items-center min-vh-100">
        <div className="col-12 col-md-7 sign-up-contianer" style={{width:'60%'}}>
            <div className="container text-center login-form">
              <div className="d-flex justify-content-center">
                <div className="main-logo" style={{ width: "146px" }}>
                  <Logo />
                </div>
              </div>
              <h3 className="sign-in-title" style={{fontSize:'36px'}}>Sign In</h3>

              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <EmailInput
                    onSubmit={handleSubmit}
                    value={email}
                    setValue={setEmail}
                    label={<label style={{ fontSize: '18px' }}>E-Mail*</label>}
                    inputName="email"
                    showLoginLink="none"
                    placeholder="E-mail address"
                    error={state.error}
                  />
                </div>
                <div className="col-12 col-md-8 ">
                  <PasswordInput
                    onSubmit={handleSubmit}
                    value={password}
                    setValue={setPassword}
                    label={<label style={{ fontSize: '18px' }}>Password*</label>}
                    inputName="password"
                    placeholder="Password"
                    error={state?.error}
                    showLoginLink="none"
                  />
                </div>
                <div className="col-12 col-md-8">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-6">
                      <Checkbox
                        uid="chkRememberMe"
                        label="Remember Me"
                        checked={chkRememberMe}
                        onChange={(value) => setChkRememberMe(value)}
                      />
                    </div>
                    <div className="col-6">
                      <h6 className="mt-3 text-end">
                        <span
                          onClick={() =>
                            history.push(`/forgotPassword?email=${email}`)
                          }
                          className="txt-forgot-password text-decoration-underline text-primary cst-cursor"
                        >
                          Forgot password?
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-8 mt-4 ">
                  <button
                    style={{
                      borderRadius: "6px",
                      fontWeight: "500",
                      fontSize: "22px",
                      color: "#fff",
                      backgroundColor: "#165093",
                    }}
                    className="btn btn-primary col-12"
                    fullwidth={true}
                    loading={state.loading}
                    text="Sign Up!"
                    onClick={(e) => {
                      setState({ ...state, error: undefined });
                      handleSubmit(e);
                    }}
                  >
                    Sign In
                  </button>
                </div>
              </div>

              <div className={styles.return_to_login_wrapper}>
                <h6 className="mt-3">
                  Don't have an account yet?
                  <span
                    className="text-decoration-underline text-primary cst-cursor"
                    onClick={() => history.push("/signup")}
                  >
                    Join us!
                  </span>
                </h6>
              </div>
            </div>
            <div className="col-md-7 col-12 model-footer cst-footer-add-css ">
              <hr className=""></hr>
              <TermsFooter isMobile={smallScreen} />
            </div>
          </div>
          <div
            className="col-md-5 d-none d-md-block hero-left-col"
            style={{ height: "100vh", "overflow-y": "auto", width:'40%' }}
          ></div>
         
        </div>
      </section>
    </>
  );
};
